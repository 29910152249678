// This file contains data/code for filtering our meters. I expect there to be some customization
// between projects. But, also a lot of reuse.
import { appData } from './meter-data';

const FILTER_TYPES = {
    SELECT_MULTI: 'Select Multi',
    BOOL: 'Bool',
};

const props = [
    {
        label: 'Routes',
        type: FILTER_TYPES.SELECT_MULTI,
        choices: function choices() {
            // TODO Upgrade this code to grab all the unique values from the data as a default
            return [
                'Brookland',
                'Fairfield',
                'Three Chopt',
                'Tuckahoe',
                'Varina',
            ];
        },
        defaultValue: function defaultValue() {
            return [
                'Brookland',
                'Fairfield',
                'Three Chopt',
                'Tuckahoe',
                'Varina',
            ];
        },
        filter: function filter(meter, selectedValues) {
            return !selectedValues.includes(meter['Magisterial District']);
        },
    },
    {
        label: 'Install Type',
        type: FILTER_TYPES.SELECT_MULTI,
        choices: function choices() {
            return [
                'Standard Meter Replacement',
                'Hourly Rate',
            ];
        },
        defaultValue: function defaultValue() {
            return [
                'Standard Meter Replacement',
                'Hourly Rate',
            ];
        },
        filter: function filter(meter, selectedValues) {
            // To determine the type of install, we have to look at the crew members and see if
            // they have any hours.
            const crew = meter['Crew Members'];
            if (crew === undefined || !Array.isArray(crew) || crew.length === 0
                || crew[0].Hours === undefined) {
                // This meter is a standard meter replacement
                return !selectedValues.includes('Standard Meter Replacement');
            }
            for (let i = 0; i < crew.length; i++) {
                if (crew[i].Hours !== undefined && crew[i].Hours > 0) {
                    // This meter is an hourly rate
                    return !selectedValues.includes('Hourly Rate');
                }
            }
            // If we got this far, we didn't find any hours. This meter is a standard meter
            // replacement.
            return !selectedValues.includes('Standard Meter Replacement');
        },
    },
    {
        label: 'Meter Size',
        type: FILTER_TYPES.SELECT_MULTI,
        choices: function choices() {
            return [
                { text: '0"', value: '0' },
                { text: '5/8"', value: '0.58' },
                { text: '3/4"', value: '0.75' },
                { text: '1"', value: '1' },
                { text: '1.25"', value: '1.25' },
                { text: '1.5"', value: '1.5' },
                { text: '2"', value: '2' },
                { text: '3"', value: '3' },
                { text: '4"', value: '4' },
                { text: '5"', value: '5' },
                { text: '6"', value: '6' },
                { text: '7"', value: '7' },
                { text: '8"', value: '8' },
                { text: 'Not Specified', value: 'Not Specified' },
            ];
        },
        defaultValue: function defaultValue() {
            return ['0', '0.58', '0.75', '1', '1.25', '1.5', '2', '3', '4', '5', '6', '7', '8',
                'Not Specified'];
        },
        filter: function filter(meter, selectedValues) {
            const size = meter['Meter Size'] || 'Not Specified';
            return !selectedValues.includes(size);
        },
    },
    {
        label: 'New Meter Size',
        type: FILTER_TYPES.SELECT_MULTI,
        choices: function choices() {
            return [
                { text: '0"', value: '0' },
                { text: '5/8"', value: '0.58' },
                { text: '3/4"', value: '0.75' },
                { text: '1"', value: '1' },
                { text: '1.25"', value: '1.25' },
                { text: '1.5"', value: '1.5' },
                { text: '2"', value: '2' },
                { text: '3"', value: '3' },
                { text: '4"', value: '4' },
                { text: '5"', value: '5' },
                { text: '6"', value: '6' },
                { text: '7"', value: '7' },
                { text: '8"', value: '8' },
                { text: 'Not Specified', value: 'Not Specified' },
            ];
        },
        defaultValue: function defaultValue() {
            return ['0', '0.58', '0.75', '1', '1.25', '1.5', '2', '3', '4', '5', '6', '7', '8',
                'Not Specified'];
        },
        filter: function filter(meter, selectedValues) {
            const size = meter['New Meter Size'] || 'Not Specified';
            return !selectedValues.includes(size);
        },
    },
    {
        label: 'Town Attention Needed',
        type: FILTER_TYPES.SELECT_MULTI,
        choices: function choices() {
            return [
                { text: 'Yes', value: true },
                { text: 'No', value: false },
            ];
        },
        defaultValue: function defaultValue() {
            return [true, false];
        },
        filter: function filter(meter, selectedValues) {
            const attentionNeeded = meter['Town Attention Needed'] || false;
            return !selectedValues.includes(attentionNeeded);
        },
    },
    {
        label: 'Completed',
        type: FILTER_TYPES.SELECT_MULTI,
        choices: function choices() {
            return [
                { text: 'Yes', value: true },
                { text: 'No', value: false },
            ];
        },
        defaultValue: function defaultValue() {
            return [true, false];
        },
        filter: function filter(meter, selectedValues) {
            const completed = meter.Completed || false;
            return !selectedValues.includes(completed);
        },
    },
    {
        label: 'Unsaved Changes',
        type: FILTER_TYPES.SELECT_MULTI,
        choices: function choices() {
            return [
                { text: 'Yes', value: true },
                { text: 'No', value: false },
            ];
        },
        defaultValue: function defaultValue() {
            return [true, false];
        },
        filter: function filter(meter, selectedValues) {
            return !selectedValues.includes(
                appData.modifiedMeters[meter.ID] !== undefined,
            );
        },
    },
    {
        label: 'Parts Needed',
        type: FILTER_TYPES.SELECT_MULTI,
        choices: function choices() {
            return [
                '7" Resetter',
                '9" Resetter',
                '12" Resetter',
                'Retro Resetter',
                '3/4" Meter Adapter',
                'Tail Pieces',
                'None',
            ];
        },
        defaultValue: function defaultValue() {
            return [
                '7" Resetter',
                '9" Resetter',
                '12" Resetter',
                'Retro Resetter',
                '3/4" Meter Adapter',
                'Tail Pieces',
                'None',
            ];
        },
        filter: function filter(meter, selectedValues) {
            let partsNeeded = meter['Parts Needed'];
            if (!Array.isArray(partsNeeded) || partsNeeded.length === 0) {
                partsNeeded = ['None'];
            }
            for (let i = 0; i < partsNeeded.length; i++) {
                if (selectedValues.includes(partsNeeded[i])) {
                    return false;
                }
            }
            return true;
        },
    },
    {
        label: 'Parts Used',
        type: FILTER_TYPES.SELECT_MULTI,
        choices: function choices() {
            return [
                '7" Resetter',
                '9" Resetter',
                '12" Resetter',
                'Retro Resetter',
                '3/4" Meter Adapter',
                'Tail Pieces',
                'None',
            ];
        },
        defaultValue: function defaultValue() {
            return [
                '7" Resetter',
                '9" Resetter',
                '12" Resetter',
                'Retro Resetter',
                '3/4" Meter Adapter',
                'Tail Pieces',
                'None',
            ];
        },
        filter: function filter(meter, selectedValues) {
            let partsUsed = meter['Parts Used'];
            if (!Array.isArray(partsUsed) || partsUsed.length === 0) {
                partsUsed = ['None'];
            }
            for (let i = 0; i < partsUsed.length; i++) {
                if (selectedValues.includes(partsUsed[i])) {
                    return false;
                }
            }
            return true;
        },
    },
    // {
    //     label: 'In Export',
    //     type: FILTER_TYPES.SELECT_MULTI,
    //     choices: function choices() {
    //         // Gather all the export values. Alternatively, we could call /api/exports to
    //         // get a list.
    //         if (!this.cachedExportIds) {
    //             const exportIds = new Set();
    //             for (let i = 0; i < appData.meters.length; i++) {
    //                 const exports = appData.meters[i].Exports;
    //                 if (exports === undefined || !Array.isArray(exports)
    //                     || exports.length === 0) {
    //                     continue;
    //                 }
    //                 for (let j = 0; j < exports.length; j++) {
    //                     exportIds.add(exports[j]);
    //                 }
    //             }
    //             this.cachedExportIds = Array.from(exportIds);
    //             this.cachedExportIds.push('Not Exported');
    //         }
    //         return this.cachedExportIds;
    //     },
    //     defaultValue: function defaultValue(choices) {
    //         return [...choices];
    //     },
    //     filter: function filter(meter, selectedValues) {
    //         const exports = meter.Exports;
    //         if (exports === undefined || !Array.isArray(exports) || exports.length === 0) {
    //             return !selectedValues.includes('Not Exported');
    //         }
    //         for (let i = 0; i < exports.length; i++) {
    //             if (selectedValues.includes(exports[i])) {
    //                 return false;
    //             }
    //         }
    //         return true;
    //     },
    // },
];

export { FILTER_TYPES, props };
