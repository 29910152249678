<!--
This component extends the vuetify v-select component to use a default value.

Attributes:
    default: The value to use if the v-model value is undefined. If default is a function, the
        function is called with appData.lastUsedSettings as the argument.
    All other attributes are passed along to the <v-select> component.
-->
<template>
    <v-select v-bind="$attrs" v-model="defaultedValue"></v-select>
</template>

<script>
import { appData } from '../meter-data';

export default {
    name: 'default-select',
    props: {
        value: [String, Number, Array],
        default: [String, Number, Function],
    },
    computed: {
        defaultedValue: {
            get: function get() {
                // If our v-model value is undefined, return null to use today's date
                if (this.value === undefined) {
                    if (typeof this.default === 'function') {
                        return this.default(appData.lastUsedSettings);
                    }
                    return this.default;
                }
                return this.value;
            },
            set: function set(value) {
                this.$emit('input', value);
            },
        },
    },
};
</script>
